
import cmsBlock from '~/mixins/cmsBlock'

export default {
  name: 'CmsMediclubBlock3',
  mixins: [cmsBlock],

  computed: {
    inMonthText () {
      const month = new Date().getMonth() + 1

      switch (month) {
        case 1: return this.$t('in January')
        case 2: return this.$t('in February')
        case 3: return this.$t('in March')
        case 4: return this.$t('in April')
        case 5: return this.$t('in May')
        case 6: return this.$t('in June')
        case 7: return this.$t('in July')
        case 8: return this.$t('in August')
        case 9: return this.$t('in September')
        case 10: return this.$t('in October')
        case 11: return this.$t('in November')
        case 12: return this.$t('in December')
      }

      return ''
    },
  },
}
