import { render, staticRenderFns } from "./CmsMediclubBlock3.vue?vue&type=template&id=7d593991&scoped=true&"
import script from "./CmsMediclubBlock3.vue?vue&type=script&lang=js&"
export * from "./CmsMediclubBlock3.vue?vue&type=script&lang=js&"
import style0 from "./CmsMediclubBlock3.vue?vue&type=style&index=0&id=7d593991&prod&lang=scss&"
import style1 from "./CmsMediclubBlock3.vue?vue&type=style&index=1&id=7d593991&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d593991",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MediClubLogo: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/MediClub/MediClubLogo.vue').default,BaseImage: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseImage.vue').default,BaseButton: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseButton.vue').default,BaseCarousel: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/carousel/BaseCarousel.vue').default,BaseSheet: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseSheet.vue').default})
